<template>
    <div class="edit">
        <h1>hi</h1>

        <router-view />
    </div>
</template>

<script>
import resizeTextareas from "@/js/resize-textareas"
// import Spacer from "@/components/spacer"
import { mapGetters } from "vuex"
// import ProjectService from "@/services/ProjectService"
// import InlineNotification from "@/components/notifications/inline-notification"

export default {
    name: 'edit',
    props: {
        hash: {
            type: String,
        }
    },
    data() {
        return {
            name: '',
            description: '',
            link: '',
            message: {
                success: false,
                message: ''
            },
            isLoading: false
        }
    },
    mounted() {
        resizeTextareas.init()
    },
    computed: {
        ...mapGetters([ 'isAuthenticated', 'currentUser' ])
    },
    async created() {
        if(!this.$store.getters.isAuthenticated) {
            this.$router.push({ name: 'login' })
        }
    },
    components: {
        // Spacer,
        // InlineNotification
    }
}
</script>

<style lang="scss" scoped>
button[type=submit] {
    .name {
        opacity: 0;
        transition: opacity 200ms ease;
    }

    &:hover {
        .name {
            opacity: 1;
        }
    }
}
</style>